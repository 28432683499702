<script>
import Registration from "@/components/Registration.vue";

export default {
  name: "MainMenu",
  components: {Registration}
}
</script>

<template>
  <div class="container-fluid border-bottom mb-4">
    <div class="container">
      <header class="d-flex flex-wrap justify-content-center py-3">
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
          <img src="@/assets/logo.png" class="bi me-2" width="50" height="50"/>
          <span class="fs-4">Dungeon Guide</span>
        </a>
        <ul class="nav nav-pills py-1">
          <li class="nav-item mx-2">
            <button class="btn btn-dark">Вход</button>
          </li>
          <li class="nav-item">
            <Registration/>
          </li>
        </ul>
      </header>
    </div>
  </div>

</template>

<style scoped>

</style>