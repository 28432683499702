<script>
import {useVuelidate} from '@vuelidate/core'
import {helpers, maxLength, minLength, required, sameAs} from '@vuelidate/validators'

const PASSWORD_REG = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/);
const EMAIL_REG = helpers.regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      msg: {
        msgRequired: 'Поле является обязательным для заполнения.',
        msgEmailInvalid: 'Поле должно подходить под формат name@domain.ru и не должно содержать в себе спецсимволов, кроме "@".',
        msgPasswordInvalid: 'Ваш пароль должен состоять только из разрешённых символов.',
        msgMinPasswordLength: 'Ваш пароль должен состоять минимум из 8 символов.',
        msgMaxPasswordLength: 'Длина вашего пароля не должена превышать 20 символов.',
        msgPasswordConfirmInvalid: 'Пароли должны совпадать.'
      },
      passwordType: 'password',
      passwordConfirmType: 'password',
      valid: {
        passwordEye: false,
        passwordConfirmEye: false,
        lowerLitter: false,
        capitalLitter: false,
        number: false,
        special: false
      },
      formReg: {
        email: '',
        password: '',
        passwordConfirm: ''
      }
    }
  },
  validations() {
    return {
      formReg: {
        email: {
          required,
          EMAIL_REG
        },
        password: {
          required,
          PASSWORD_REG,
          minLengthValue: minLength(8),
          maxLengthValue: maxLength(20)
        },
        passwordConfirm: {
          sameAsRawValue: sameAs(this.formReg.password)
        }
      }
    }
  },
  computed: {
    disableBtnReg() {
      return this.v$.formReg.email.$invalid ||
          this.v$.formReg.password.$invalid ||
          this.v$.formReg.passwordConfirm.$invalid
    },
    validPassword() {
      const PASSWORD_REG_LOWER_LETTERS = /(?=.*[a-z])/;
      const PASSWORD_REG_CAPITAL_LETTERS = /(?=.*[A-Z])/;
      const PASSWORD_REG_NUMBER = /(?=.*\d)/;
      const PASSWORD_REG_SPECIAL = /(?=.*[@$!%*?&])/;
      this.valid.lowerLitter = PASSWORD_REG_LOWER_LETTERS.test(this.formReg.password)
      this.valid.capitalLitter = PASSWORD_REG_CAPITAL_LETTERS.test(this.formReg.password)
      this.valid.number = PASSWORD_REG_NUMBER.test(this.formReg.password)
      this.valid.special = PASSWORD_REG_SPECIAL.test(this.formReg.password)
      return !(this.valid.lowerLitter || this.valid.capitalLitter || this.valid.number || this.valid.special)
    }
  },
  methods: {
    switchValidatorStyle(validator) {
      return {
        'is-invalid': validator.$error,
        'is-valid': !validator.$error && validator.$dirty
      }
    },
    passwordHelpBarStyle(validator) {
      return {'btn-success': validator}
    },
    registrationUser() {
      console.log(this.formReg);
      this.clearProps();
    },
    clearProps() {
      for (let input in this.formReg) {
        this.formReg[input] = '';
      }

      for (let flag in this.valid) {
        this.valid[flag] = false;
      }
      this.passwordType = 'password'
      this.passwordConfirmType = 'password'
      this.v$.$reset();
    }
  }
}

</script>

<template>
  <!-- button trigger modal -->
  <button @click="clearProps"
          type="button"
          class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#registrationModal">Регистрация
  </button>
  <!-- modal -->
  <div class="modal fade" id="registrationModal" tabindex="-1"
       aria-labelledby="registrationModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="registrationModalLabel">Окно регистации</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <!-- form -->
          <form @submit.prevent="registrationUser" novalidate>

            <div class="mb-3">

              <label for="floatingInputEmail" class="form-label">Почта</label>

              <div class="form-floating">
                <input v-model="formReg.email"
                       @blur="v$.formReg.email.$touch()"
                       :class="switchValidatorStyle(v$.formReg.email)"
                       type="email" class="form-control" id="floatingInputEmail" placeholder="name@domain.ru">
                <label for="floatingInputEmail">name@domain.ru</label>
                <div id="emailHelp" class="form-text">Укажите свою действующую почту.</div>

                <div v-show="v$.formReg.email.required.$invalid"
                     class="invalid-feedback">{{ msg.msgRequired }}
                </div>
                <div v-show="v$.formReg.email.EMAIL_REG.$invalid"
                     class="invalid-feedback">{{ msg.msgEmailInvalid }}
                </div>
              </div>

            </div>

            <div class="mb-3">

              <label for="inputPassword" class="form-label">Пароль</label>

              <div class="input-group mb-3">
                <input v-model="formReg.password"
                       @blur="v$.formReg.password.$touch()"
                       :class="switchValidatorStyle(v$.formReg.password)"
                       :type="passwordType" id="inputPassword"
                       class="form-control">
                <button @click="valid.passwordEye =! valid.passwordEye;
                        passwordType = valid.passwordEye ? 'text' : 'password'"
                        type="button" tabindex="-1" class="btn btn-outline-secondary btn-border-radius">
                  <div v-show="valid.passwordEye">
                    <font-awesome-icon :icon="['fas', 'eye']"/>
                  </div>
                  <div v-show="!valid.passwordEye">
                    <font-awesome-icon :icon="['fas', 'eye-slash']"/>
                  </div>
                </button>

                <div v-show="this.v$.formReg.password.required.$invalid"
                     class="invalid-feedback">{{ msg.msgRequired }}
                </div>
                <div v-show="v$.formReg.password.PASSWORD_REG.$invalid"
                     class="invalid-feedback">{{ msg.msgPasswordInvalid }}
                </div>
                <div v-show="v$.formReg.password.minLengthValue.$invalid"
                     class="invalid-feedback">{{ msg.msgMinPasswordLength }}
                </div>
                <div v-show="v$.formReg.password.maxLengthValue.$invalid"
                     class="invalid-feedback">{{ msg.msgMaxPasswordLength }}
                </div>

              </div>

            </div>

            <div class="btn-group mb-3 d-flex" role="group">
              <button :class="passwordHelpBarStyle(valid.lowerLitter)"
                      type="button" tabindex="-1" class="btn btn-disabled">a-z
              </button>
              <button :class="passwordHelpBarStyle(valid.capitalLitter)"
                      type="button" tabindex="-1" class="btn btn-disabled">A-Z
              </button>
              <button :class="passwordHelpBarStyle(valid.number)"
                      type="button" tabindex="-1" class="btn btn-disabled">0-9
              </button>
              <button :class="passwordHelpBarStyle(valid.special)"
                      type="button" tabindex="-1" class="btn btn-disabled">@$!%*?&
              </button>
            </div>

            <div class="mb-3">

              <label for="inputPasswordConfirm" class="form-label">Повторите пароль</label>

              <div class="input-group mb-3">
                <input :disabled="validPassword"
                       v-model="formReg.passwordConfirm"
                       @blur="v$.formReg.passwordConfirm.$touch()"
                       :class="switchValidatorStyle(v$.formReg.passwordConfirm)"
                       :type='passwordConfirmType' id="inputPasswordConfirm" class="form-control">
                <button :disabled="validPassword"
                        @click="valid.passwordConfirmEye =! valid.passwordConfirmEye;
                        passwordConfirmType = valid.passwordConfirmEye ? 'text' : 'password'"
                        type="button" tabindex="-1" class="btn btn-outline-secondary btn-border-radius">

                  <div v-show="valid.passwordConfirmEye">
                    <font-awesome-icon :icon="['fas', 'eye']"/>
                  </div>
                  <div v-show="!valid.passwordConfirmEye">
                    <font-awesome-icon :icon="['fas', 'eye-slash']"/>
                  </div>

                </button>

                <div v-show="v$.formReg.passwordConfirm.sameAsRawValue.$invalid"
                     class="invalid-feedback">{{ msg.msgPasswordConfirmInvalid }}
                </div>

              </div>

            </div>

            <div class="d-grid gap-2">
              <button
                  :disabled="disableBtnReg"
                  type="submit" class="btn btn-outline-dark" data-bs-dismiss="modal">Зарегистрироваться
              </button>
            </div>
          </form>
        </div> <!-- modal-body  -->
      </div> <!-- modal-content -->
    </div> <!-- modal-dialog -->
  </div> <!-- modal -->
</template>

<style scoped>
.btn-disabled {
  pointer-events: none;
  /*border-color: #198754;*/
  border-color: #7d8689;
}

.btn:hover {
  background-color: #cecfd1;
}

.btn-border-radius {
  width: 46px;
  -webkit-border-top-right-radius: 0.375rem !important;
  -webkit-border-bottom-right-radius: 0.375rem !important;
}
</style>